<template>
  <div
    v-editable="blok"
    class="component-hero -z-10 flex w-full flex-col items-center justify-start !px-0"
    :class="blok.style === 'full-page' ? 'min-h-dvh' : 'tablet:min-h-[min(48rem,calc(100dvh-4rem))] tablet:flex-row'"
  >
    <sb-image
      v-if="blok.image?.filename"
      :blok="blok.image"
      height="800"
      :width="blok.style === 'full-width' || blok.style === 'full-page' ? 1720 : 1000"
      :sizes="`${blok.style === 'full-width' || blok.style === 'full-page' ? '640px tablet:1024px desktop:1720px ext:2880px' : '390px tablet:640px desktop:1000px'}`"
      :class="[
        'img-container',
        blok.style === 'full-width' || blok.style === 'full-page'
          ? 'absolute left-1/2 top-0 min-h-full min-w-[min(100%,2280px)] max-w-[2280px] -translate-x-1/2 object-cover object-top'
          : 'mt-32 h-full w-full border-b-4 border-blue-dark object-contain object-right-bottom tablet:absolute tablet:bottom-0 tablet:right-4 tablet:max-h-[calc(100%-7rem)] tablet:max-w-[66dvw] tablet:border-b-0 desktop:right-24 desktop:w-auto dark:border-blue [@media(width>1720px)]:right-[calc(50dvw-50rem)]',
        { 'full-width': blok.style === 'full-width', 'full-page': blok.style === 'full-page' },
      ]"
      fit-inside
      decorative
      loading="eager"
      :preload="{ fetchPriority: 'high' }"
    />
    <hero-card
      v-editable="blok"
      :blok="blok"
      :text="text"
      :headline="blok.headline"
      :overline="overline"
      :ext-width="blok.style === 'full-page'"
      :link="{ text: blok.buttonText, url: blok.buttonLink }"
      class="justify-center phone:mt-auto phone:pt-8 tablet:mt-16 desktop:mt-36"
      :class="{
        'mt-44 phone:justify-end': blok.style === 'full-width',
        '!mt-auto self-center pt-32 text-center': blok.style === 'full-page',
      }"
    />
    <member-badge class="z-0 mb-4 ml-auto tablet:mb-6 tablet:mt-auto" />
  </div>
</template>

<script setup lang="ts">
import type { HeroStoryblok } from "~/storyblok-component-types"

const props = defineProps<{ blok: HeroStoryblok; }>()

const text = renderRTF(props.blok?.text)
const overline = renderRTF(props.blok?.overline)
</script>

<style lang="scss">
.component-hero .img-container {
  &.full-width {
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 33%) 33%, black 66%);
  }

  &.full-page {
    mask-image: radial-gradient(farthest-side at 50% 50%, rgba(0, 0, 0, 33%) 40%, black 80%);
  }

  &:not(:is(.full-width, .full-page)) {
    // mask-image: radial-gradient(closest-side circle at 50% 100%, black 50%, transparent),
    //   radial-gradient(farthest-side circle at 50% 50%, black 70%, transparent);

    @screen tablet {
      mask-image: radial-gradient(farthest-side ellipse at 50% 50%, black 75%, transparent 100%),
        radial-gradient(farthest-side ellipse at 50% 100%, black 75%, transparent 100%);
    }
  }
}
</style>
